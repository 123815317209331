<template>
  <main class="player">
    <PageHeader :title="`Player Profile - ${ gameName }`" />
    <transition name="fade" mode="out-in">
      <main v-if="player" key="content">
        <h1>
          <div class="title-data">
            <span>{{ player.name }}</span>
            <div class="title-other">
              <img
                :src="`/icons/flags/${player.data.origin}.svg`"
                :alt="$getCountryName(player.data.origin)"
                :title="$getCountryName(player.data.origin)"
              />
              <span>{{ player.data.name }}</span>
            </div>
          </div>
          <div class="title-socials">
            <a v-for="soc in player.socials" :key="soc.type" target="__blank" :href="soc.link">
              <i :class="`fa-brands fa-${soc.type}`"></i>
            </a>
          </div>
        </h1>
        <Tabs :tabs="tabs" v-model="tab" fit @change="tabClick"></Tabs>
        <TabItems v-model="tab" :tabs="tabs">
          <template slot="info">
            <div class="panel information">
              <div class="info-item">
                <span class="info-item-label">Full name</span>
                <span class="info-item-value"
                  >{{ player.data.name }}</span
                >
              </div>
              <div class="info-item">
                <span class="info-item-label">Current team</span>
                <span class="info-item-value" v-if="currentTeam !== null">
                  <img :src="$cdn('team-logo', currentTeam)" alt="" />
                  {{ currentTeam.name }}</span
                >
                <span class="info-item-value" v-else>
                  <span>-</span>
                </span>
              </div>
              <div class="info-item">
                <span class="info-item-label">Age</span>
                <span class="info-item-value">{{ player.data.age }}</span>
              </div>
              <div class="info-item">
                <span class="info-item-label">Origin</span>
                <span class="info-item-value">
                  <img
                    :src="`/icons/flags/${player.data.origin}.svg`"
                    :alt="$getCountryName(player.data.origin)"
                    :title="$getCountryName(player.data.origin)"
                  />
                  {{ $getCountryName(player.data.origin) }}
                </span>
              </div>
              <div class="info-item">
                <span class="info-item-label">Device</span>
                <span class="info-item-value" v-if="player.data.device !== null">
                  <i v-if="player.data.device.os === 'ios'" class="fa-brands fa-apple"></i>
                  <i v-if="player.data.device.os === 'android'" class="fa-brands fa-android"></i>
                  <i v-if="player.data.device.type === 'phone'" class="fa-solid fa-mobile"></i>
                  <i v-if="player.data.device.type === 'tablet'" class="fa-solid fa-tablet"></i>
                  <span>{{ player.data.device.name }}</span>
                </span>
                <span class="info-item-value" v-else>
                  <span>Unknown</span>
                </span>
              </div>
            </div>
          </template>
          <template slot="teams">
            <h2>Current team</h2>
            <router-link
              v-if="currentTeam !== null" 
              :to="`/${game}/team/${currentTeam._id}`"
              custom
              v-slot="{ navigate }"
            >
              <div class="panel current-team" @click="navigate">
                <div class="team-logo">
                  <img
                    :src="$cdn('team-logo', currentTeam)"
                    :alt="currentTeam.name"
                    :title="currentTeam.name"
                  />
                </div>
                <div class="team-info">
                  <div class="team-name">{{ currentTeam.name }}</div>
                  <div class="team-join">Joined: {{ $getDateBetter(currentTeam.player.join) }} </div>
                </div>
              </div>
            </router-link>
            <span v-else class="panel no-team">No current team</span>
            <h2>Previous teams</h2>
            <router-link
              v-for="team in teams.filter(t => t.player.leave !== null)"
              :key="team._id"
              :to="`/${game}/team/${team._id}`"
              custom
              v-slot="{ navigate }"
            >
              <div class="panel previous-team" @click="navigate">
                <span class="team-name">{{ team.name }}</span>
                <span class="team-dates"
                  >{{ $getDateBetter(team.player.join) }} - {{ $getDateBetter(team.player.leave) }}</span
                >
              </div>
            </router-link>
          </template>
          <template slot="matches">
            <cops-match-preview
              v-for="match in player.matches"
              :key="match._id"
              :match="match"
            ></cops-match-preview>
          </template>
        </TabItems>
      </main>
      <main v-else-if="!player && !playerInvalid" key="loading">
        <div class="no-content">
          <i class="fa-solid fa-circle-notch fa-spin fa-3x"></i>
        </div>
      </main>
      <main v-else-if="playerInvalid" key="no-player">
        <div class="no-content">
          <h2>Player not found.</h2>
          <button @click="$back">
            <i class="fa-solid fa-chevron-left fa-lg"></i>
            <span>Back</span>
          </button>
        </div>
      </main>
    </transition>
  </main>
</template>

<script>
import axios from "axios";
import CopsMatchPreview from '../components/c-ops/copsMatchPreview.vue';
export default {
  components: {
    CopsMatchPreview
  },
  data: () => ({
    playerInvalid: false,
    player: null,
    tab: "info",
    tabs: [
      {
        name: "info",
        label: "Info",
      },
      {
        name: "teams",
        label: "Teams",
      },
      {
        name: "matches",
        label: "Matches",
      },
      {
        name: "stats",
        label: "Stats",
      },
      {
        name: "news",
        label: "News",
      },
    ],
  }),
  computed: {
    game() {
      return this.$store.state.game;
    },
    gameName() {
      return this.$games.find((g) => g.slug === this.game).name;
    },
    upcomingMatches() {
      return this.player.matches.filter((m) => m.start > Date.now());
    },
    finishedMatches() {
      return this.player.matches.filter((m) => m.ended);
    },
    teams() {
      let teams = [];
      this.player.teams.forEach((t) => {
        t.player = t.players.find((p) => p._id === this.player._id);
        teams.push(t);
      });
      return teams;
    },
    currentTeam() {
      let team = this.teams.find((t) => t.player.leave === null);
      return team || null;
    }
  },
  created() {
    axios
      .get(this.$api("/players/" + this.$route.params.player))
      .then((res) => {
        console.log(res.data);
        this.player = res.data;
        let gamePlayer = res.data.games.find((gp) => gp.game === this.game);
        if (!gamePlayer) this.playerInvalid = true;
        this.player = Object.assign(this.player, gamePlayer);
      })
      .catch((err) => {
        console.log(err);
        this.playerInvalid = true;
      });
  },
  methods: {
    tabClick(tab) {
      this.$router.push({ query: { tab } });
    },
  },
  watch: {
    $route: {
      handler(route) {
        if (!route.query.tab) {
          this.tab = "info";
        } else {
          this.tab = route.query.tab;
        }
      },
      immediate: true,
    },
  },
};
</script>

<style>
</style>